<template>
  <div class="htmlWidget" v-html="content" />
</template>

<script>
export default {
  props: {
    htmlWidget: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: '',
    };
  },
  mounted() {
    // TODO: migrate to Axios
    // TODO: Make this generic to handle any url instead of just insights-html
    fetch(`https://dj4.datajoe.com/widget/insights-html.php?${this.htmlWidget}`)
      .then((response) => response.text())
      .then((html) => {
        if (html.includes('UNAUTHORIZED ACCESS')) {
          return;
        }

        // TODO: set the vuex parameter to recalculate the offset height of the chart

        this.content = html;
      });
  },
};
</script>
