<template>
  <nav role="navigation" aria-label="search facet navigation">
    <div class="navbar-brand">
      <div class="navbar-item has-text-grey is-hidden-touch">
        FILTERS
      </div>
      <a v-for="facet in facets" :key="facet.name" class="navbar-item">
        <div class="dropdown is-hoverable">
          <div class="dropdown-trigger">
            <a class="button">
              <span>{{ facet.name }}</span>
              <span class="icon is-small has-text-grey-dark">
                <fa icon="angle-down" />
              </span>
            </a>
          </div>
          <div class="dropdown-menu">
            <div v-if="facet.type === 'array'" class="dropdown-content is-small">
              <FacetFilter :attribute="facet.key" />
            </div>

            <div v-else-if="facet.type === 'string'" class="dropdown-content is-small">
              <StringFacetFilter :attribute="facet.key" />
            </div>

            <div v-else-if="facet.type === 'tree'" class="dropdown-content is-small">
              <ais-hierarchical-menu
                :attributes="[facet.key + '.lvl0', facet.key + '.lvl1']"
                :class-names="{
                  'ais-HierarchicalMenu': 'menu-list',
                  'ais-HierarchicalMenu-count': 'tag is-pulled-right',
                  'ais-HierarchicalMenu-item--active': 'has-text-weight-bold',
                  'ais-HierarchicalMenu-item': 'has-text-weight-normal',
                }"
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import FacetFilter from '@/components/InstantSearch/FacetFilter.vue';
import StringFacetFilter from '@/components/InstantSearch/StringFacetFilter.vue';

export default {
  computed: {
    ...mapGetters({
      facets: 'fields/facets',
    }),
  },
  components: {
    FacetFilter,
    StringFacetFilter,
  },
};
</script>
