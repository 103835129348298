<template>
  <div v-if="primaryFilter" class="control is-hidden-desktop">
    <div class="dropdown is-hoverable">
      <div class="dropdown-trigger">
        <a class="button">
          <span>{{ primaryFilter.name }}</span>
          <span class="icon is-small has-text-grey-dark">
            <fa icon="angle-down" />
          </span>
        </a>
      </div>
      <div class="dropdown-menu">
        <div v-if="primaryFilter.type === 'array'" class="dropdown-content is-small">
          <FacetFilter :attribute="primaryFilter.key" />
        </div>

        <div v-else-if="primaryFilter.type === 'string'" class="dropdown-content is-small">
          <StringFacetFilter :attribute="primaryFilter.key" />
        </div>

        <div v-else-if="primaryFilter.type === 'tree'" class="dropdown-content is-small">
          <ais-hierarchical-menu
            :attributes="[primaryFilter.key + '.lvl0', primaryFilter.key + '.lvl1']"
            :class-names="{
              'ais-HierarchicalMenu': 'menu-list',
              'ais-HierarchicalMenu-count': 'tag is-pulled-right',
              'ais-HierarchicalMenu-item--active': 'has-text-weight-bold',
              'ais-HierarchicalMenu-item': 'has-text-weight-normal',
            }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FacetFilter from '@/components/InstantSearch/FacetFilter.vue';
import StringFacetFilter from '@/components/InstantSearch/StringFacetFilter.vue';

export default {
  computed: {
    ...mapGetters({
      primaryFilter: 'directory/primaryFilter',
    }),
  },
  components: {
    FacetFilter,
    StringFacetFilter,
  },
};
</script>
