<template>
  <ais-state-results id="featuredPrimaryBanner" class="column is-12">
    <div
      v-if="isFeaturedPrimary(state)"
      slot-scope="{ state }"
      class="is-featured-primary-banner"
    >
      <div class="container">
        <h1 class="title is-1" v-html="primaryFilterData(state).name" />
        <p class="subtitle" v-html="primaryFilterData(state).description" />
      </div>

      <HtmlWidget
        v-if="primaryFilterData(state).html_widget"
        :html-widget="primaryFilterData(state).html_widget"
      />
    </div>
    <span v-else />
  </ais-state-results>
</template>

<script>
import { mapGetters } from 'vuex';
import HtmlWidget from '@/components/dj4/HtmlWidget.vue';

export default {
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    primaryFilterSettings() {
      return this.currentDirectory.primary_filter?.settings;
    },
    primaryFilterField() {
      return this.currentDirectory.primary_filter?.field;
    },
    primaryFilterFieldType() {
      return this.currentDirectory.fields
        .find((field) => field.key === this.primaryFilterField)
        ?.type;
    },
    featuredPrimaryFieldNames() {
      return this.primaryFilterSettings?.map((field) => field.name);
    },
  },
  methods: {
    isFeaturedPrimary(state) {
      // Checks to see if the selected facet is included somewhere in our
      // `primary_filter.settings`
      const hasBanner = this.getRefinements(state)
        ?.some((facet) => this.featuredPrimaryFieldNames?.includes(facet));

      this.$store.commit('SET_BANNER', hasBanner);

      return hasBanner;
    },
    primaryFilterData(state) {
      const active = this.getRefinements(state)?.[0];
      return this.primaryFilterSettings?.find((field) => field.name === active);
    },
    getRefinements(state) {
      if (this.primaryFilterFieldType === 'string') {
        return state.hierarchicalFacetsRefinements?.[this.primaryFilterField];
      }

      if (this.primaryFilterFieldType === 'array') {
        return state.facetsRefinements?.[this.primaryFilterField];
      }

      return null;
    },
  },
  components: {
    HtmlWidget,
  },
};
</script>
